@import './mixins.scss';

@media only screen and (max-width: 999px) {
  .section-area {
    display: none!important;
  }
}

@media (min-width: 1000px) and (max-width: 1600px) {
  .section-icon {
    li {
      width: 1.1rem!important;
      height: 1.1rem!important;
      background-size: 1.4rem!important;
    }

  }
  .section-tooltip {
    font-size: 0.7rem!important;
  }
}

.section-area {
  position: fixed;
  width: 5%;
  height: 80vh;
  @include flex(column, center, center);
  background-color: #111111;
  z-index: 0;

  .section-icon {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    a {
      @include socialIcons(1.5rem);
    }

    .section-tooltip{
      margin-left: -85px;
      font-size: 0.8rem;

      &:after {
        border: none;
      }
    }
  }
}
