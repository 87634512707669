.home {
  color: #000;
  line-height: 1.3;
  position: relative;
  overflow: hidden;

  .lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
    z-index: 1;

    .line {
      position: absolute;
      width: 1px;
      height: 100%;
      top: 0;
      left: 50%;
      background: rgba(255, 255, 255, 0.1);
      overflow: hidden;
      z-index: 1;
      opacity: 0.7;
      transform: skew(-15deg);
    
      &::after {
        content: '';
        display: block;
        position: absolute;
        height: 15vh;
        width: 100%;
        top: -50%;
        left: 0;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #000 75%, #000 100%);
        animation: drop 7s 0s infinite;
        animation-fill-mode: forwards;
        animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    
      }
      
      &:nth-child(1) {
        margin-left: -40%;
    
        &::after {
          animation-delay: 2s;
        }
      }

      &:nth-child(2) {
        margin-left: -20%;
    
        &::after {
          animation-delay: 2s;
        }
      }
      
      &:nth-child(4) {
        margin-left: 20%;
    
        &::after {
          animation-delay: 1s;
        }
      }

      &:nth-child(5) {
        margin-left: 40%;
    
        &::after {
          animation-delay: 1s;
        }
      }
      }
  }
}
    
@keyframes drop {
  0% {
    top: -50%
  }
  100% {
    top: 110%
  }
}