@import './variables.scss';
@import './mixins.scss';

@media only screen and (max-width: 999px) {
  .title-box {
    width: 100% !important;
    text-align: center;
  }

  .msg-box {
    margin: auto;
  }

  .img-box {
    display: none;
  }
}

.home {
  @include flex(row, center);
  background-color: #fff;

  .title-box {
    width: 50%;
    @include flex(initial, initial, center);
    z-index: 10;
    margin: 1rem;

    .msg-box {
      background-color: #111;
      box-shadow: 4px -4px 0px 1px rgba(77, 77, 77, 1);
      font-family: monospace;
      font-size: 1rem;
      font-weight: 100;
      width: 10rem;
      color: #fff;
      padding: 1rem;
    }

    h1 {
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 600;
      font-size: 5rem;

    }

    // ======================= Animação da logo ========================
    .stack {
      display: grid;
      grid-template-columns: 1fr;
    }

    .stack h1 {
      font-weight: bold;
      grid-row-start: 1;
      grid-column-start: 1;
      font-size: 4rem;
      --stack-height: calc(100% / var(--stacks) - 1px);
      --inverse-index: calc(calc(var(--stacks) - 1) - var(--index));
      --clip-top: calc(var(--stack-height) * var(--index));
      --clip-bottom: calc(var(--stack-height) * var(--inverse-index));
      clip-path: inset(var(--clip-top) 0 var(--clip-bottom) 0);
      animation: stack 340ms cubic-bezier(.46, .29, 0, 1.24) 1 backwards calc(var(--index) * 120ms), glitch 2s ease infinite 1s alternate-reverse;
    }

    .stack h1:nth-child(odd) {
      --glitch-translate: 8px;
    }

    .stack h1:nth-child(even) {
      --glitch-translate: -8px;
    }

    @keyframes stack {
      0% {
        opacity: 0;
        transform: translateX(-50%);
        text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
      }

      60% {
        opacity: 0.5;
        transform: translateX(50%);
      }

      80% {
        transform: none;
        opacity: 1;
        text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
      }

      100% {
        text-shadow: none;
      }
    }

    @keyframes glitch {
      0% {
        text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
        transform: translate(var(--glitch-translate));
      }

      2% {
        text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
      }

      4%,
      100% {
        text-shadow: none;
        transform: none;
      }
    }

    // ===========================================================

    h3 {
      color: $color-home;
      font-weight: 100;
    }

    ul {
      display: flex;
      width: 100%;
      justify-content: center;
      gap: 2rem;
      margin: 1rem 0;

      a {
        @include socialIcons(1.8rem);
        filter: grayscale(1);
        cursor: pointer;

        &:hover {
          filter: grayscale(0);
        }
      }
    }
    .curriculum {
      @include flex(row, center);

      .btn-curriculum {
        @include button($color-home);
        background-color: #fff;
        margin: 0 auto;
  
        &:hover {
          background-color: $color-home;
          color: #fff;
        }
      }
    }
  }

  .img-box {
    width: 40%;
    z-index: 10;
  }
}