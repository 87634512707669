@import './variables.scss';
@import './mixins.scss';

@media screen and (max-width: 460px) {
  .menu-btn {
    padding: 0 3vh!important;
  }
}

@media screen and (max-width: 768px) {
  .menu-btn {
    font-size: 1rem!important;
  }
}

@media screen and (max-width: 1599px) {
  .menu-btn {
    font-size: 1.1rem!important;
  }
}

nav {
  @include flex(initial, initial, center);
  height: 10vh;
  font-size: 1.5rem;
  z-index: 1;

  ul {
    @include flex(initial, initial, center);
    max-width: 1000px;
    flex-grow: 1;
    flex-shrink: 1;
    width: 100%;

    li {
      @include flex(initial, center);
      height: 100%;
      transform: skew(-15deg);
      transition: 0.2s linear;
      cursor: pointer;
      overflow: hidden;

      &:hover {
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      }

      &:nth-child(1):hover {
        background-color: $color-about !important;
      }

      &:nth-child(2):hover {
        background-color: $color-skills !important;
      }

      &:nth-child(3):hover {
        background-color: $color-home !important;
      }

      &:nth-child(4):hover {
        background-color: $color-projects !important;
      }

      &:nth-child(5):hover {
        background-color: $color-contact !important;
      }

      .menu-btn {
        @include flex(initial, center, center);
        color: rgb(255, 255, 255);
        font-family: Oswald;
        font-size: 1.3rem;
        width: 100%;
        height: 100%;
        padding: 0 5vh;
        transform: skew(10deg);
        background: none;
        border: none;
      }
    }
  }
}