@mixin buttonSection($color, $url, $right) {
  position: absolute;
  width: 3rem;
  height: 2rem;
  right: $right;
  border: none;
  background: url($url) $color 50% no-repeat;
  background-size: 1rem;
  transition: 0.1s ease-in-out;
}

@mixin flex($direction, $align:initial, $justify: initial) {
  display: flex;
  flex-direction: $direction;
  align-items: $align;
  justify-content: $justify;
}

@mixin button($color, $width: initial) {
  border: 2px solid $color;
  width: $width;
  font-weight: bolder;
  color: $color;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  padding: 1rem;
}

@mixin socialIcons($size) {
  width: 1.5em;
  height: 1.5em;
  list-style: none;
  background-size: $size;
  cursor: pointer;
  margin: 2rem 0;
  filter: grayscale(1);
  filter: brightness(30);
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;

  &:hover {
    filter: grayscale(0);
    transform: scale(1.2);
  }
}